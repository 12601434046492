<template>
  <el-container class="patroladd">
     
    <el-header class="box add bb">
         <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item>权限管理</el-breadcrumb-item>
       <el-breadcrumb-item>菜单权限</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.company_type_id">编辑菜单权限</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增菜单权限</el-breadcrumb-item>
    </el-breadcrumb>
        <div><el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="">
    <el-form :inline="true"  status-icon ref="form"  :model="form"  class="demo-ruleForm">
      <el-collapse v-model="activeNames" @change="handleChange">
       <el-collapse-item v-for="(item,index) in menu_list" :key="index" :title="item.menu_name" :name="index">
        <el-row class="mt20 f14">
           <el-checkbox-group v-model="checkboxGroup1" @change="handleCheckedChange(checkboxGroup1)">
         <el-col  v-for="(item1,index) in item.menu_list" :key="index"  :span="6" class="tl" >
           <el-checkbox  v-if="item1.menu_level==2" class="ml10 mb20 disib"  :label="item1.menu_id" :value="item1.menu_id" :key="item1.menu_id" border size="medium">{{item1.menu_name}}</el-checkbox>
         </el-col>
         </el-checkbox-group>
        </el-row>
       </el-collapse-item>
      </el-collapse>
      <div class="tl mt15">
             <el-button  class=""  size="medium" type="primary" @click="submitForm('form')">提交</el-button>
          </div>
     <!-- <el-row class="box mt20  f14">
         <el-col  v-for="(item,index) in menu_list" :key="index"  :span="22" class="tl" >
           <div v-if="item.menu_level==1" class="patroladd-title1 f14 bgcfbe tl mb20 pl15">{{item.menu_name}}</div>
          
             <el-checkbox-group v-model="checkboxGroup1" @change="handleCheckedChange(checkboxGroup1)">
        <el-col  v-for="(item1,index) in item.menu_list" :key="index"  :span="22" class="tl" >
        
           <el-checkbox  v-if="item1.menu_level==2" class="ml10 mb20 disib"  :label="item1.menu_id" :value="item1.menu_id" :key="item1.menu_id" border size="medium">{{item1.menu_name}}</el-checkbox>

        </el-col>
        
        </el-checkbox-group>
        </el-col>
         
     </el-row> -->
     </el-form>
     </el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,session } from '../../../util/util'
export default {
  data () {
    return {
      menu_list:'',
      activeNames: ['1'],
      aa:[],
      bb:[],
      cc:[],
        form: {
            isbach:'2',
            company_type_id:'',
          pager_list:[]
      },
       checked1: true,
        checked2: false,
        checkboxGroup1: [],
        checkboxGroup3: [],
        checkboxGroup2:[],
        checkboxGroup4: [],
        list:[],
        list1:[],
        user_id_add:'',
        user_name_add:'',
    }
  },
     created () {
      this.user = local.get('user');
       this.user_id_add=this.user.user_id;
        this.user_name_add=this.user.user_name;
       this.form.company_type_id=session.get('params').id?String(session.get('params').id):'';
     this.init()
  },
  methods: {
     init (params) {
          axios.get('/pc/menu/all').then((v) => {
        this.menu_list=v.data;
      })
       if(this.form.company_type_id){
          axios.get('/pc/company-type-menu/single',{company_type_id:this.form.company_type_id}).then((v) => {

v.data.forEach(item=>{
    if(item.menu_level==2){
        this.checkboxGroup1.push(item.menu_id)
         this.checkboxGroup4=this.checkboxGroup1; 
    }
})
      })    
       } 
    },
    getArrDifference(arr1, arr2) {
        return arr1.concat(arr2).filter(function(v, i, arr) {
            return arr.indexOf(v) === arr.lastIndexOf(v);
        });
    },
    handleCheckedChange(val){
      let aaa;
      var len1=val.length
      var len2=this.checkboxGroup4.length;
       this.list=[];
      this.form.pager_list= [];
   this.menu_list.forEach(item=>{
          item.menu_list.forEach(iem1=>{
            val.forEach(item2=>{
              if(iem1.menu_id==item2){
                 this.list.push({company_type_id:this.form.company_type_id,menu_id:iem1.menu_parent_id,user_id_add:this.user_id_add,user_name_add:this.user_name_add})
                this.form.pager_list.push({company_type_id:this.form.company_type_id,menu_id:iem1.menu_id,user_id_add:this.user_id_add,user_name_add:this.user_name_add})
              }
          })
          })
      })
       this.form.pager_list=this.form.pager_list.concat(this.list)
     const id='menu_id'
this.form.pager_list=this.form.pager_list.reduce((all,next)=>all.some((item)=>item[id]==next[id])?all:[...all,next],[]);

    },
     submitForm(form){
          let paramas=this.form
         this.$refs[form].validate((valid) => {
        if (valid) {
                axios.post('/pc/company-type-menu/save', paramas).then((v) => {
      this.$router.back()
      })
           
            
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      },
      handleChange(){}
  }
}
</script>
<style lang="scss" scoped>
.patroladd{
   overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image{width: 160px; height: 160px;margin-right: 10px}
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroladd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
  .patroladd-title1{height: 34px;line-height: 34px;}
.el-checkbox{    display: flex;
    justify-content: left;
    align-items: center;}
/deep/ .el-collapse-item .el-collapse-item__content{padding-bottom: 0px;}
}

</style>
